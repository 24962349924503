import {reqBannersList,reqSubsidyList,reqServiceTicketList,reqFreeProductList,reqCategoryList} from '@/api'
const state={
  bannerList:[],//轮播图数据
  subsidyList:[], //享补贴数据
  serviceList:[],// 服务券数据
  freeList:[],//免费数据
  categoryList:[],//分类数据
}
const mutations={
  //轮播图数据
  RECEIVE_BANNER_LIST(state,bannerList){
    state.bannerList=bannerList
  },
  //享补贴数据
  RECEIVE_SUBSIDY_LIST(state,subsidyList){
    state.subsidyList=subsidyList
  },
  //服务券数据
  RECEIVE_SERVICE_LIST(state,serviceList){
    state.serviceList=serviceList
  },
  //免费数据
  RECEIVE_FREE_LIST(state,freeList){
    state.freeList=freeList
  },
  //分类数据
  RECEIVE_CATEGORY_LIST(state,categoryList){
    state.categoryList=categoryList
  }
}
const actions={
    ///调用接口获取轮播图数据
    async getBannersList({commit},type){
      const params = new URLSearchParams();
      params.append("type",type)
      const result=await reqBannersList(params)
      if(result.code==200){
        let bannerList=result.data
        commit('RECEIVE_BANNER_LIST',bannerList)
      }
    },
    //调用接口获取享补贴数据
    async getSubsidyList({commit},params){
      const result=await reqSubsidyList(params)
      if(result.code==200){
        let subsidyList= result.data.ds1
        commit('RECEIVE_SUBSIDY_LIST',subsidyList)
        
      }
    },
    //调用接口获取服务券数据
    async getTicketList({commit},params){
      const result=await reqServiceTicketList(params)
      if(result.code==200){
        let serviceList=result.data.ds1
        commit('RECEIVE_SERVICE_LIST',serviceList)
      }
    },
    //调用接口获取免费数据
    async getFreeList({commit},params){
      const result=await reqFreeProductList(params)
      if(result.code==200){
        let freeList=result.data.ds1
        commit('RECEIVE_FREE_LIST',freeList)
      }
    },
    //调用接口获取分类数据
    async getCategoryList({commit}){
      const result=await reqCategoryList()
      if(result.code==200){
        let categoryList=result.data.ds1
        categoryList.forEach((item)=>{
          item.value=item.VALUE
          item.text=item.LABEL
        })
        commit('RECEIVE_CATEGORY_LIST',categoryList)
      }
    }

  
}
const getters={
}
export default {
  state,
  mutations,
  actions,
  getters
}