import Vue from 'vue';
import App from './App.vue';
//引入router
import router from './router';
//引入store
import store from './store';
//引入所有的api接口函数
import * as API from '@/api';
import 'element-ui/lib/theme-chalk/index.css';
import Element from 'element-ui'
import 'lib-flexible/flexible'
//引入vant
// import Vant from 'vant';
//请求挂载原型上
import ajax from '@/api/ajax.js'
import ajaxtwo from '@/api/ajaxtwo.js'
import * as echarts from 'echarts'
import 'vant/lib/index.css';

//把API接口暴露在每一个组件的实例对象上
Vue.prototype.$API=API
//把接口暴露在每一个组件的实例对象上
Vue.prototype.$ajax =ajax
Vue.prototype.$http =ajaxtwo
Vue.prototype.$echarts = echarts
//使用vant
// Vue.use(Vant)
//使用Element
Vue.use(Element)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
