<template>
  <div id="app">
    <router-view></router-view>
      <div class="common_link">
        <div class="container clearfix" style="display:flex;justify-content: center;">
<a onclick="tk(this);return false;" href="http://www.gov.cn/" target="_blank"  class="link_text"><img src="./assets/xm20190118_03.png" alt style="width:100%;height:100%;" /></a><a onclick="tk(this);return false;" href="https://portal.agri.cn/dist/#/main/index/" target="_blank" class="link_text">农业农村部网站</a>
            <a onclick="tk(this);return false;" href="http://www.xmsyj.moa.gov.cn/" target="_blank" class="link_text">畜牧兽医局</a>
            <a onclick="tk(this);return false;" href="http://www.zys.moa.gov.cn/" target="_blank" class="link_text">种业管理司</a>
            <a onclick="tk(this);return false;" href="http://www.moa.gov.cn/fw/xzsp/" target="_blank" class="link_text">行政许可查询</a>
        </div>
    </div>
      <div class="footer">
        <div class="container">
          <ul>
            <li><a href="https://www.nahs.org.cn/wzxx/gywm/" target="_blank">关于我们 | </a></li>
            <li><a href="https://www.nahs.org.cn/wzxx/wzsm/" target="_blank">网站声明 | </a></li>
            <li><a href="https://www.nahs.org.cn/wzxx/wzdt/" target="_blank">网站地图 | </a></li>
            <li><a href="https://www.nahs.org.cn/wzxx/lxwmw/" target="_blank">联系我们 | </a></li>
            <li><a href="javascript:void(0);">站长统计</a></li>
          </ul>
          <div>
            <span>主办单位：全国畜牧总站</span>
            <span
              ><a href="https://beian.miit.gov.cn/" target="_blank"
                >京ICP备09074986号-4</a
              ></span
            >
            <span>公网安备 京公网安备11010802013</span>
          </div>
          <div>
            <span class="disnone">最佳浏览模式1024*768分辨率</span>
            <span>网站保留所有权，未经许可不得复制、镜像</span>
          </div>
          <a
            target="_blank"
            class="footer_img"
            href="http://bszs.conac.cn/sitename?method=show&id=6126688332654569E053022E1AAC819D"
          >
            <img src="./assets/xm20190118_04.png" alt />
          </a>
        </div>
      </div>
  </div>
</template>

<script>


export default {
  name: 'App',

}
</script>

<style>
.footer {height: 136px;border-top: 3px solid #1f86d2;background: #f6f6f6;text-align: center;padding-top: 26px;font-size: 14px;}
.footer .container {position: relative;}
.footer ul {text-align: center;}
.footer ul li {display: inline-block;font-size: 16px;}
.footer ul li a:hover {color: #3275d6;}
.footer div div {margin-top: 18px;}
.footer div span {display: inline-block;margin: 0 15px;}
.footer_img {position: absolute;top: 15px;left: 455px;}
.common_link {margin-top:20px;border-top: 1px solid #d3e3fb;padding: 10px 0;}
/* .common_link a {float: left;} */
.link_text {margin-left: 20px;display: inline-block;width: 218px;height: 36px;line-height: 36px;background: #f6f6f6;font-size: 15px;text-align: center;}
.link_text:hover {color: #3275d6;}
</style>
