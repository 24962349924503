//引入组件
export default [

  {
    path:'/',
    name:'login',
    component: () => import('@/views/login/index')
  },
  //首页
  {
    path:'/UserCenter',
    name:'UserCenter',
    component: () => import('@/views/UserCenter/index')
  },
  //注册
  {
    path:'/register',
    name:'register',
    component: () => import('@/views/register/index')
  },
  //忘记密码
  {
    path:'/Forgetpassword',
    name:'Forgetpassword',
    component: () => import('@/views/Forgetpassword/index')
  },
  //个人中心
  {
    path:'/Replacement',
    name:'Replacement',
    component: () => import('@/views/Replacement/index')
  },
  //草业数据
  {
    path:'/grasslanddata',
    name:'grasslanddata',
    component: () => import('@/views/grasslanddata/index')
  },


//首页2
{
  path:'/index',
  name:'index',
  component: () => import('@/views/index/index')
},




  
  //入口备份
  {
    path:'/Portalbackup',
    name:'Portalbackup',
    component: () => import('@/views/Portalbackup/index')
  },
  
      //路由的重定向
  {
    path:'/',
    redirect: '/'
  },





  
]