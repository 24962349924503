//引入vue
import Vue from "vue"
//引入vue-router
import VueRouter from 'vue-router'
//引入routes
import routes from './routes'
//声明使用vue-router
Vue.use(VueRouter)
//实例化路由器并暴露出去
export default new VueRouter({
  mode: 'history',
  base: '/home/',
  routes
})
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

