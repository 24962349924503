//引入vue
import Vue  from 'vue'
//引入vuex
import Vuex from 'vuex'
//声明使用vuex
Vue.use(Vuex)
//引入state
import state from './state'
//引入mutations
import mutations from './mutations'
//引入actions
import actions from './actions'
//引入getters
import getters from './getters'
//引入modules
import modules from './modules'


//实例化store对象
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules
})
